import React from 'react';
import '../../App.css';
import './About.css';

function About() {
    return (
    <div className='about-container'>
    <div className='about-div'>
      <h1>ABOUT</h1>
      <p>At Hi Stack, we are your dedicated partner in material handling solutions. With a commitment to excellence, 
        our skilled team provides comprehensive forklift services, including repairs, parts sales, and tire 
        solutions. Whether you're looking to upgrade your fleet with new or used forklifts, seeking flexible rentals, 
        or ensuring equipment safety through load testing, we've got you covered. Choose us for reliable expertise, 
        quality service, and a tailored approach to meet your unique business needs. Elevate your efficiency with us.
        </p>
    </div>
    <div className='partner-div'>
      <h1>Authorized Agents</h1>
      <p>We are exclusive authorised dealers for Kelmar Industries SA and Heli brand forklifts</p>
      <div className='kalmar'></div>
      <div className='heli'></div>
    </div>
    </div>

    );
  }

  export default About;