import React from 'react';
import '../../App.css';
import './Contact.css';

 function Contact() {
    return(
      <div className='contact-container'>
      <div className='contact-div'>
        <h1>Contact Us</h1>
        <p>58 A3 Ceramic Curve, Alton, Richards Bay, South Africa</p>
        <p>histackforklift@gmail.com</p>
        <p>035 751 8016 | 064 035 4034</p>
      </div>
      <div className='map-address'>
      <iframe 
      title='map'
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3498.2279264949716!2d32.034689475885095!3d-28.742611075604774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1efa22d59094d03d%3A0x5a22d8c52919db28!2s58%20Ceramic%20Curve%2C%20Alton%2C%20Richards%20Bay%2C%203900!5e0!3m2!1sen!2sza!4v1715095265105!5m2!1sen!2sza" 
      width="648" 
      height="405" 
      style={{border: '0'}}
      allowfullscreen="" 
      loading="lazy">
      </iframe>
      </div>
      <div className='hours-div'>
        <div className='hours'>
          <h1>Hours of Operation</h1>
          <p>Open for Business</p>
          <p>Mon - Thurs: 7:30am - 4:30pm</p>
          <p>Fri: 7:30am - 2:30am</p>
        </div>
      </div>
      </div>
    );
  }

  export default Contact;