import React from 'react';
import '../../App.css';
import './Services.css'
 function Services() {
    return (
      <div className='Services-container'>
        <div className='rs-img'>

        </div>
        <div className='rs-div'>
          <h1><b>Repairs and Services</b></h1>
          <h4>Swift repairs for all forklift models</h4>
        </div>

        <div className='ps-img'></div>
        <div className='ps-div'>
        <h1><b>Part Sales</b></h1>
        <h4>Genuine parts for various machines</h4>
        </div>

        <div className='lt-img'></div>
        <div className='lt-div'>
        <h1><b>Load Testing</b></h1>
        <h4>Ensure equipment safety and compliance</h4>
        </div>

        <div className='rt-img'></div>
        <div className='rt-div'>
        <h1><b>Rentals</b></h1>
        <h4>Flexible long and short-term options</h4>
        </div>

        <div className='nu-img'></div>
        <div className='nu-div'>
        <h1><b>New & Used Sales</b></h1>
        <h4>Upgrade your fleet confidently</h4>
        </div>
    </div>
    
    );
  }

  export default Services;