import React from 'react'
import './Footer.css'

function Footer() {
  return (
    
      <div className='footer'>
        <h4>Hi Stack Forklift & Industrial</h4>
        
        <h4>Your Trusted Partner in Forklift and Industrial Solutions</h4>
      </div>
    
    
  )
}

export default Footer
