import React from 'react';
import '../../App.css'
import HomeSection from '../HomeSection';
import Footer from '../Footer';

function Home(){
    return(
        <>
            <HomeSection />
            <Footer />
        </>
    );
}

export default Home;